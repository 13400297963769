import styled from "styled-components"

import { minD, maxD } from "../../../assets/styles/helpers/devices"

import { unstyledList } from "../../global/common/essentials"

export const Boxes = styled.ul`
  @media ${minD.tablet} {
    display: flex;
    flex-wrap: wrap;
  }

  margin: -1px 0 0 -1px;
`

export const UnstyledBoxes = styled(Boxes)`
  ${unstyledList}
`

export const EqualBoxes = styled(Boxes)`
  padding-bottom: 60px;
`

export const Box = styled.li`
  margin: 0 -1px -1px 0;
  border: 1px solid var(--border-green);

  padding: var(--box-padding-vertical) var(--box-padding-horizontal);
`

export const EqualBox = styled(Box)`
  display: flex;
  align-items: center;

  padding: var(--box-padding-vertical) calc(var(--box-padding-horizontal) * 2);

  @media ${maxD.tablet} {
    width: 100%;
  }
`

export const BoxIcon = styled.div`
  svg {
    width: 55px;
    height: 55px;
    flex-shrink: 0;

    fill: var(--main-green);

    @media ${maxD.mobileL} {
      width: 40px;
      height: 40px;
    }
  }
`

export const BoxHeader = styled.header`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`

export const BoxPoints = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  width: var(--circle-size, 100px);
  height: var(--circle-size, 100px);

  border-radius: 50%;
  background-color: var(--faded-green);

  color: var(--main-green);
  font-size: 1.3rem;
  margin: 0 4rem 2.5rem 0;
`

export const BoxLogo = styled.div`
  width: 90px;
  margin-bottom: 2.5rem;
`
